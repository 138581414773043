.form-wrapper {
  margin: 30px auto;
  overflow: hidden;
  padding: 7px 8px 13px;
  max-width: 530px;
  border: 1px solid #40b345;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.search-box input {
  border: 1px solid #ccc;
  padding: 4px;
  background-color: #4ca424 !important;
  color: #fff !important;
  background-clip: padding-box;
  border: 0 solid #fff !important;
  padding: 5px 16px;
}

.srch_frm {
  margin-top: 10px;
  color: #000;
  border: solid 1px #fff;
  background-color: #fff;
  width: 57%;
  float: left;
}

.topmargin {
  margin-top: 10px;
  padding: 5px 10px;
  margin-left: 5px;
}

.submit_btn {
  background-color: #1bd930 !important;
  color: #fff !important;
}

.float_rt {
  float: right;
}

.topmargin {
  margin-top: 10px;
  padding: 5px 10px;
  margin-left: 8px;
}

.tree_icon {
  display: flex;
  justify-content: space-evenly;
  /* background: #111797; */
  margin: 30px;
  padding: 13px;
  border-radius: 6px;
  font-size: 18px;
  border: 1px solid #40b345;
  transition: all 0.3s ease-in-out;
}

.tree_name {
  color: white;
}

.tree_name img {
  width: 40px;
  height: 40px;
}

.card {
  background-color: white;
    box-shadow: unset;
    border: unset;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.bg-main-blue {
  --tw-bg-opacity: 1;
  background-image: linear-gradient(150deg, #f44336 0%, #4200f1 78%);
}

.rounded-full {
  border-radius: 9999px;
}
.w-7\.5 {
  width: 1.875rem;
}
.h-7\.5 {
  height: 1.875rem;
}

.bg-white-200 {
  background-color: #efeff4;
}

.lksnflf {
  /* margin-left: 6rem; */
  background: #30188a;
  padding: 15px;
  box-shadow: inset 0px 0px 10px 3px #fff;
  border-radius: 0.5rem;
  width: 100%;
}

.PDev {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.contentt {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.contentt span {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

.usdtCoin {
  height: 50px;
  width: 50px;
  margin-right: 7px;
  margin-top: 16px;
}

.text-white-500 {
  color: #4214c233;
}

.stroke-current {
  stroke: currentColor;
}
.w-5 {
  width: 1.25rem;
}
.h-5 {
  height: 1.25rem;
}

.rounded-full {
  border-radius: 9999px;
}
.w-5 {
  width: 1.25rem;
}
.h-5 {
  height: 1.25rem;
}

.h-9{
  height: 3rem;
}

.w-9{
  width: 3rem;
}

.treeview {
  float: none;
  padding: 0px;
  margin: 0px;
}

.TreeImgMian {
  color: #fff;
}

.css-1vs3jbz:only-of-type {
  padding: 0px;
}

.css-1vs3jbz {
  flex: 1 1 auto;
  text-align: center;
  list-style-type: none;
  position: relative;
}

.eEckWd {
  padding: 5px;
  display: inline-block;
  position: relative;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.HoverNodeItemMain {
  display: none;
  position: absolute;
  width: 405px;
  border-radius: 5px;
  padding: 10px;
  background: transparent linear-gradient(90deg, #8fd41f 0%, #ffe569 100%) 0% 0%
    no-repeat padding-box !important;
  color: black;
  margin-left: -176px !important;
  z-index: 1;
  margin-top: 40px;
}

.css-a2eqq {
  padding-inline-start: 0px;
  margin: 0px;
  display: flex;
  --line-height: 20px;
  --line-width: 2px;
  --line-color: red;
  --line-border-radius: 10px;
  --line-style: solid;
  --node-padding: 5px;
}

.css-to7de5 {
  display: flex;
  padding-inline-start: 0px;
  margin: 0px;
  padding-top: 20px;
  position: relative;
}

.css-to7de5::before {
  content: "";
  position: absolute;
  top: 0px;
  height: 20px;
  box-sizing: border-box;
  left: calc(50% - 1px / 2);
  width: 0px;
  border-left: 1px solid red;
}

.css-1vs3jbz {
  flex: 1 1 auto;
  text-align: center;
  list-style-type: none;
  position: relative;
  border-left: 1px solid red;
}

.css-1vs3jbz:first-of-type::before {
  border: 0px none !important;
}

.css-1vs3jbz::before,
.css-1vs3jbz::after {
  content: "";
  position: absolute;
  top: 0px;
  height: 20px;
  box-sizing: border-box;
  right: 50%;
  width: 50%;
}

.css-1vs3jbz:first-of-type::after {
  border-radius: 5px 0 0 0;
}

.css-1vs3jbz::after {
  left: 50%;
  border-left: 1px solid red;
}

.css-1vs3jbz:last-of-type::before {
  border-right: 1px solid red !important;
  border-radius: 0 5px 0 0;
}

@media (max-width: 768px) {
  .form-wrapper {
    text-align: left;
  }

  .srch_frm {
    width: 100%;
  }

  .contentt {
    padding-top: 1rem;
  }

  .contentt span {
    font-size: 1.3rem;
    padding: 10px;
  }

  .lksnflf {
    padding: 17px;
  }

  .float_rt {
    float: inherit;
  }
}

@media only screen and (max-width: 575px) {
  .tree_icon {
    flex-direction: column;
  }

  .tree_name {
    margin-bottom: 10px;
  }

  .tree li > div{
    padding: 5px;
  }

  
}


.popover__title {
  font-size: 17px;
    line-height: 30px;
  text-decoration: none;
  color: white;
  text-align: center;
  margin-left: 6px;
}

.popover__wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -110px;
  transform: translate(0, 10px);
  background-color: #56a434;
  color: white !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  border-radius: 10px;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #56a434 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}

.contteett {
  font-size: 13px;
  width: 300px;
}

p {
  margin-bottom: 1em;
}

@media screen and (max-width: 850px) {
  .contteett {
    font-size: 10px;
    /* width: 300px;
    display: flex !important; */
    justify-content: center;
  }

  .popover__content {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }

  .popover__content:before {
    right: calc(50% - -124px);
  }
.vertical{
overflow: scroll;
}
  .kzsxjka {
     width: max-content; 
    overflow: scroll !important;
    overflow-x: scroll;
  }
}

a.modsddal-open {
  display: block;
  padding: 20px;
  color: #424242;
  font-weight: bold;
  margin: 50px auto;
  width: 200px;
  background: #e0e0e0;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
}
.modsddal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
}
.modsddal:target {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: +1;
}
.modsddal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, -200%);
  transition: all 0.5s ease-in-out;
  perspective: 1000;
  outline: 1px solid transparent;
  opacity: 0;
}
.modsddal:target .modsddal-content {
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;
  z-index: 9999;
  opacity: 1;
}
.modsddal-close {
  float: right;
  text-decoration: none;
  padding: 22px 22px;
  color: #424242;
  font-weight: 800;
  transition: all 0.5s ease-in-out;
  z-index: +1;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 0 4px 0 0;
}
.modsddal-close:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
.modsddal-content h3 {
  padding: 20px;
  display: block;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
  background: rgba(2, 162, 239, 1);
  color: #fff;
  border-radius: 4px 4px 0 0;
}

.modsddal-areaksnc input[type="radio"] {
  display: none;
}
.modsddal-areaksnc label {
  float: left;
  display: block;
  padding: 10px 20px;
  font-weight: 700;
  cursor: pointer;
  z-index: +2;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.modsddal-areaksnc input[type="radio"]:checked + label {
  background: #fff;
  color: #424242;
}
.tab-list {
  display: block;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  overflow: hidden;
  height: 150px;
}
.tab-list:before {
  content: "";
  display: block;
  height: 0;
  clear: both;
}
.modsddal-tab {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
  transform: translateX(-150%);
  transition: all 300ms cubic-bezier(0, 0, 0.4, 1);
  opacity: 0;
  top: 0px;
  position: absolute;
  height: 150px;
  perspective: 1000;
}
#opentab1:checked ~ .tab-list .tab1 {
  transform: translateX(0%);
  opacity: 1;
}
#opentab2:checked ~ .tab-list .tab2 {
  transform: translateX(0%);
  opacity: 1;
}
#opentab3:checked ~ .tab-list .tab3 {
  transform: translateX(0%);
  opacity: 1;
}

.tree {
  padding: 0;
}
.tree:not(:empty):before,
.tree:not(:empty):after,
.tree ul:not(:empty):before,
.tree ul:not(:empty):after,
.tree li:not(:empty):before,
.tree li:not(:empty):after {
  display: block;
  position: absolute;
  content: "";
}
.tree ul,
.tree li {
  position: relative;
  margin: 0;
  padding: 0;
}
.popover__wrapper a{
  text-align: center;
}
.tree li {
  list-style: none;
}
.tree li > div {
  color: #222 !important;
  padding: 0px 10px;
  
  display: inline-block;
}

.kqjdkq{
  font-size: 9px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.contteett p,
.modsddal-areaksnc h6 {
  color: white !important;
}
.tree.cascade li {
  margin-left: 24px;
}
.tree.cascade li div {
  margin-top: 12px;
}
.tree.cascade li:before {
  border-left: 1px solid red;
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
}
.tree.cascade li:after {
  border-top: 1px solid red;
  width: 12px;
  left: -12px;
  top: 24px;
}
.tree.cascade li:last-child:before {
  height: 24px;
  top: 0;
}
.tree.cascade > li:first-child:before {
  top: 24px;
}
.tree.cascade > li:only-child {
  margin-left: 0;
}
.tree.cascade > li:only-child:before,
.tree.cascade > li:only-child:after {
  content: none;
}
.tree.horizontal li {
  display: flex;
  align-items: center;
  margin-left: 24px;
}
.tree.horizontal li div {
  margin: 6px 0;
}
.tree.horizontal li:before {
  border-left: 1px solid red;
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
}
.tree.horizontal li:first-child:before {
  height: 50%;
  top: 50%;
}
.tree.horizontal li:last-child:before {
  height: 50%;
  bottom: 50%;
  top: auto;
}
.tree.horizontal li:after,
.tree.horizontal li ul:after {
  border-top: 1px solid red;
  height: 0;
  width: 12px;
  top: 50%;
  left: -12px;
}
.tree.horizontal li:only-child:before {
  content: none;
}
.tree.horizontal li ul:after {
  left: 0;
}
.tree.horizontal > li:only-child {
  margin-left: 0;
}
.tree.horizontal > li:only-child:before,
.tree.horizontal > li:only-child:after {
  content: none;
}
.tree.vertical ul {
  display: flex;
  justify-content: center;
}
.tree.vertical li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tree.vertical li div {
  margin: 11px 0px;
  margin-right: 3px;
}
.tree.vertical li:before {
  border-left: 2px solid #0066ff;
  height: 25px;
  width: 0;
  top: 0;
}
.tree.vertical li:after {
  border-top: 2px solid #0066ff;
  height: 0;
  width: 100%;
}
.tree.vertical li:first-child:after {
  border-top: 2px solid #0066ff;
  height: 0;
  width: 50%;
  left: 50%;
}
.tree.vertical li:last-child:after {
  border-top: 2px solid #0066ff;
  height: 0;
  width: 50%;
  right: 50%;
}
.tree.vertical li:only-child:after {
  content: none;
}
.tree.vertical li ul:before {
  border-left: 2px solid #0066ff;
  height: 25px;
  width: 0;
  top: -23px;
}
.tree.vertical > li:only-child:before,
.tree.vertical > li:only-child:after {
  content: none;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li,
.tree.vertical.cascade-3 > li > ul > li > ul > li,
.tree.vertical.cascade-2 > li > ul > li,
.tree.vertical.cascade-1 > li,
.tree.vertical .cascade {
  flex-direction: column;
  align-items: start;
  padding: 0 12px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:before,
.tree.vertical.cascade-3 > li > ul > li > ul > li:before,
.tree.vertical.cascade-2 > li > ul > li:before,
.tree.vertical.cascade-1 > li:before,
.tree.vertical .cascade:before {
  left: 24px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li:after,
.tree.vertical.cascade-2 > li > ul > li:after,
.tree.vertical.cascade-1 > li:after,
.tree.vertical .cascade:after {
  left: 0;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:first-child:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li:first-child:after,
.tree.vertical.cascade-2 > li > ul > li:first-child:after,
.tree.vertical.cascade-1 > li:first-child:after,
.tree.vertical .cascade:first-child:after {
  left: 24px;
  width: 100%;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:last-child:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li:last-child:after,
.tree.vertical.cascade-2 > li > ul > li:last-child:after,
.tree.vertical.cascade-1 > li:last-child:after,
.tree.vertical .cascade:last-child:after {
  left: 0;
  width: 24px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul,
.tree.vertical.cascade-3 > li > ul > li > ul > li ul,
.tree.vertical.cascade-2 > li > ul > li ul,
.tree.vertical.cascade-1 > li ul,
.tree.vertical .cascade ul,
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li,
.tree.vertical.cascade-3 > li > ul > li > ul > li li,
.tree.vertical.cascade-2 > li > ul > li li,
.tree.vertical.cascade-1 > li li,
.tree.vertical .cascade li {
  display: block;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul:before,
.tree.vertical.cascade-3 > li > ul > li > ul > li ul:before,
.tree.vertical.cascade-2 > li > ul > li ul:before,
.tree.vertical.cascade-1 > li ul:before,
.tree.vertical .cascade ul:before,
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li ul:after,
.tree.vertical.cascade-2 > li > ul > li ul:after,
.tree.vertical.cascade-1 > li ul:after,
.tree.vertical .cascade ul:after,
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:before,
.tree.vertical.cascade-3 > li > ul > li > ul > li li:before,
.tree.vertical.cascade-2 > li > ul > li li:before,
.tree.vertical.cascade-1 > li li:before,
.tree.vertical .cascade li:before,
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li li:after,
.tree.vertical.cascade-2 > li > ul > li li:after,
.tree.vertical.cascade-1 > li li:after,
.tree.vertical .cascade li:after {
  border: none;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li div,
.tree.vertical.cascade-3 > li > ul > li > ul > li div,
.tree.vertical.cascade-2 > li > ul > li div,
.tree.vertical.cascade-1 > li div,
.tree.vertical .cascade div {
  margin: 0;
  margin-top: 12px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li,
.tree.vertical.cascade-3 > li > ul > li > ul > li li,
.tree.vertical.cascade-2 > li > ul > li li,
.tree.vertical.cascade-1 > li li,
.tree.vertical .cascade li {
  margin-left: 24px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:before,
.tree.vertical.cascade-3 > li > ul > li > ul > li li:before,
.tree.vertical.cascade-2 > li > ul > li li:before,
.tree.vertical.cascade-1 > li li:before,
.tree.vertical .cascade li:before {
  border-left: 1px solid red;
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
}
.tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:after,
.tree.vertical.cascade-3 > li > ul > li > ul > li li:after,
.tree.vertical.cascade-2 > li > ul > li li:after,
.tree.vertical.cascade-1 > li li:after,
.tree.vertical .cascade li:after {
  border-top: 1px solid red;
  width: 12px;
  height: 0;
  left: -12px;
  top: 24px;
  content: "";
}
.tree.vertical.cascade-4
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  li:last-child:before,
.tree.vertical.cascade-3 > li > ul > li > ul > li li:last-child:before,
.tree.vertical.cascade-2 > li > ul > li li:last-child:before,
.tree.vertical.cascade-1 > li li:last-child:before,
.tree.vertical .cascade li:last-child:before {
  height: 24px;
  top: 0;
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .tree.vertical li div {
    margin: 2px 0px;
  }

  .tree li > div {
    color: #222 !important;
    padding: 0px;
    display: inline-block;
  }

  .tree.vertical li:before {
    height: 8px;
  }
}

.akhriPutti{
  margin-left: 1px;
}

.kdsjflke{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
}

.kdsjflkerre{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
}

.kdsjflke span:hover{
  color: #4200f1;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .h-9{
    height: 2rem;
  }
  
  .w-9{
    width: 2rem;
  }
}