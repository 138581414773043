/* Modal.css */

.modal-overlay {
    position: absolute;
    top: 0;
    left: 100px;
    width: 50vw;
    height: 50vh;
    background: rgba(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Adjust to 90% for smaller screens */
    max-width: 400px; /* Limit max-width to 400px */
    position: relative;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 20px;
    color: blue; 
    font-weight: 600;
    font-size: 30px;
  }
  